import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useAlert as alert } from '@hooks/modal/useAlert';

/**
 * @description 유저 상태 변경 /projectManage/exposureStatus
 * @returns {changeStatus: (status: {
 *   projectId: string;
 *   exposureStatus: boolean;
 *   info: { companyName: string; projectTitle: string };
 * }) => void}
 */
export const usePutExposureStatus = () => {
  const queryClient = useQueryClient();
  const { openHandle: openAlert, closeHandle } = alert();
  const { openHandle } = useToast();
  //#region api call
  const getData = async (status: {
    projectId: string;
    exposureStatus: boolean;
    info: { companyName: string; projectTitle: string };
  }) => {
    return await api
      .put('/projectManage/exposureStatus', status)
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: changeStatus } = useMutation({
    mutationFn: getData,
    onSuccess: (data, variables) => {
      const { exposureStatus } = variables;
      openHandle({
        text: `프로젝트가 ${
          exposureStatus ? '노출' : '미노출'
        } 상태로 변경되었습니다.`,
        severity: 'success'
      });
      queryClient.invalidateQueries(['/projectManage/allList']);
    },
    onError: (error: any, variables) => {
      if (error.errors && error.errors.length > 0) {
        openAlert({
          title: '노출 상태 변경 불가',
          text: `${variables.info.companyName}-${variables.info.projectTitle}<br/>프로젝트에는 진행 중인 작업이 있어<br/>미노출 상태로 변경할 수 없습니다.`,
          cancelLabel: '',
          ok: () => closeHandle()
        });
      } else {
        openHandle({
          text: `노출 상태 변경에 실패하였습니다.`,
          severity: 'error'
        });
      }
    }
  });
  //#endregion

  return {
    changeStatus
  };
};
