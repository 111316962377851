import { ReactElement, useEffect, useState } from 'react';
import { TPInputCheckBox } from '../input/TPInput.checkBox';
import { FilterWrap, SelectList, FilterInput, Gap } from './TPSelect.Style';
import { IconBulUp, IconBulDown } from '@resources/icon';
import { IMember } from '@common/interfaces';

/**
 * @description Select check PM
 * @param props
 */
export const TPSelectCheckManager = (props: {
  label: string | ReactElement;
  PMList: IMember[];
  selectOptions: (string | number)[];
  showAvatar?: boolean;
  changeSelect: (state: string) => void;
}) => {
  const { label, PMList, selectOptions, showAvatar, changeSelect } = props;
  const [showCheckList, setShowCheckList] = useState(false);

  let showSelectValue = PMList.filter((v) =>
    selectOptions.includes(String(v.userId))
  )
    .map((item) => item.name)
    .join(', ');
  if (showSelectValue.length > 10) {
    showSelectValue = `${showSelectValue.slice(0, 10)} ...`;
  }

  useEffect(() => {
    const actualOptionCount = PMList.filter(
      (option) => option.userId !== '' && option.status !== 'INACTIVE'
    ).length;
    if (
      actualOptionCount === selectOptions.length &&
      PMList.some((el) => el.name === '전체') &&
      !selectOptions.includes('')
    ) {
      changeSelect('');
    }
  }, [PMList, selectOptions]);

  return (
    <FilterWrap
      onMouseEnter={() => {
        setShowCheckList(true);
      }}
      onMouseLeave={() => {
        setShowCheckList(false);
      }}
    >
      <FilterInput>
        <div className="label--checked">
          <span className="label">{label}</span>
          <span className="checked">{showSelectValue}</span>
        </div>
        {showCheckList ? (
          <IconBulDown className="icon" />
        ) : (
          <IconBulUp className="icon" />
        )}
      </FilterInput>
      <Gap />
      {showCheckList && (
        <SelectList showCheckList={showCheckList}>
          {PMList?.map((item, index) => {
            return (
              <TPInputCheckBox
                option={item.name}
                avatarUrl={item.avatarImgUrl}
                optionKey={String(item.userId)}
                selectOptions={selectOptions}
                onClick={changeSelect}
                showAvatar={showAvatar}
                disabled={item.status === 'INACTIVE'}
                key={index}
              />
            );
          })}
        </SelectList>
      )}
    </FilterWrap>
  );
};
