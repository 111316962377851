import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hooks/modal/useAlert';
import { useGetCompanyNameValid } from '@hooks/company/getCompanyNameValid';
import { useEffect, useState } from 'react';
import { useGetCompany } from '@hooks/company/getCompany';
import { usePageFunc } from '../utils';

/**
 * /company
 * @description 고객사 수정
 * @returns {
 *     companyName: string,
 *     setCompanyName: function,
 *     companyNameValidCheck: function,
 *     iconImgUrl: string,
 *     setIconImgUrl: function,
 *     iconImgUrlValidCheck: function,
 *     viewUrl: string,
 *     validInfo: { companyName: string, iconImgUrl: string}
 *     clickCreate: function,
 *     closeModal: function
 * }
 */
export const usePutCompany = (companyId: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const { getDateTime } = usePageFunc();
  const { companyData } = useGetCompany(companyId);
  const {
    companyNameValidData,
    companyNameValid,
    validCompanyNameError,
    validCompanyName
  } = useGetCompanyNameValid();

  const [companyName, setCompanyName] = useState('');
  const [iconImgUrl, setIconImgUrl] = useState('');
  const [viewUrl, setViewUrl] = useState('');
  const [dateFilter, setDateFilter] = useState<{
    startDateTime: string;
    endDateTime: string;
  }>({
    startDateTime: '',
    endDateTime: ''
  });
  const [validInfo, setValidInfo] = useState({
    companyName: '',
    iconImgUrl: '',
    dateFilter: ''
  });

  const changeCompanyName = (value: string) => {
    if (companyData?.companyName === value) {
      setValidInfo({
        ...validInfo,
        companyName: 'valid'
      });
    } else {
      setValidInfo({
        ...validInfo,
        companyName: ''
      });
    }
    setCompanyName(value);
  };

  const changeIconImgUrl = (value: string) => {
    setIconImgUrl(value);
    setValidInfo({
      ...validInfo,
      iconImgUrl: ''
    });
  };

  // const changeIconImgUrl = (value: string) => {
  //   setIconImgUrl(value);
  //   setViewUrl(value);

  //   // 원래 데이터와 같은 값으로 돌아왔을 때 valid 처리
  //   if (companyData?.iconImgUrl === value) {
  //     setValidInfo((prev) => ({
  //       ...prev,
  //       iconImgUrl: 'valid'
  //     }));
  //   } else {
  //     setValidInfo((prev) => ({
  //       ...prev,
  //       iconImgUrl: ''
  //     }));
  //   }
  // };

  const companyNameValidCheck = () => {
    companyNameValid(companyName);
  };

  const iconImgUrlValidCheck = () => {
    const regex = /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    if (iconImgUrl) {
      const valid = regex.test(iconImgUrl);
      setValidInfo({
        ...validInfo,
        iconImgUrl: valid ? 'valid' : 'invalid:regex'
      });
      setViewUrl(iconImgUrl);
    } else {
      setValidInfo({
        ...validInfo,
        iconImgUrl: 'invalid'
      });
      return;
    }
  };

  const clickCreate = () => {
    alertHandle({
      title: '수정 확인',
      text: '입력한 내용으로 수정하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        updateCompany();
        navigate('/company', { replace: true });
      }
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '수정 취소',
      text: '수정 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/company', { replace: true });
      }
    });
  };
  //#region api call
  const getData = async () => {
    return await api
      .put(`/company`, {
        companyId,
        companyName,
        iconImgUrl,
        startDateTime: getDateTime({
          date: new Date(dateFilter.startDateTime),
          filter: 'start'
        }),
        endDateTime: getDateTime({
          date: new Date(dateFilter.endDateTime),
          filter: 'end'
        })
      })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: updateCompany } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/company/list']);
      openHandle({
        text: `고객사 정보가 수정되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `고객사 수정에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  useEffect(() => {
    if (validCompanyName) {
      const err = validCompanyNameError as any;
      if (err.status === 400) {
        setValidInfo({
          ...validInfo,
          companyName: 'invalid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          companyName: 'invalid:regex'
        });
      }
    } else {
      if (companyNameValidData?.status === 200) {
        setValidInfo({
          ...validInfo,
          companyName: 'valid'
        });
      }
    }
    return () => {};
  }, [companyNameValidData, validCompanyName]);

  useEffect(() => {
    if (companyData) {
      setCompanyName(companyData.companyName);
      setIconImgUrl(companyData.iconImgUrl);
      setViewUrl(companyData.iconImgUrl);
      setValidInfo({
        ...validInfo,
        companyName: 'valid',
        iconImgUrl: 'valid'
      });
    }
    return () => {};
  }, [companyData]);

  useEffect(() => {
    if (companyData) {
      setCompanyName(companyData.companyName);
      setIconImgUrl(companyData.iconImgUrl);
      setViewUrl(companyData.iconImgUrl);
      setDateFilter({
        startDateTime: companyData?.startDateTime ?? '',
        endDateTime: companyData?.endDateTime ?? ''
      });
      // validInfo 상태를 한 번에 업데이트
      setValidInfo({
        companyName: 'valid',
        iconImgUrl: companyData.iconImgUrl ? 'valid' : '',
        dateFilter:
          companyData?.startDateTime && companyData?.endDateTime
            ? 'valid'
            : 'invalid'
      });
    }
  }, [companyData, companyId]);

  // useEffect(() => {
  //   setDateFilter({
  //     startDateTime: companyData?.startDateTime ?? '',
  //     endDateTime: companyData?.endDateTime ?? ''
  //   });
  // }, [companyData]);

  // useEffect(() => {
  //   if (dateFilter.startDateTime && dateFilter.endDateTime) {
  //     setValidInfo({
  //       ...validInfo,
  //       dateFilter: 'valid'
  //     });
  //   } else {
  //     setValidInfo({
  //       ...validInfo,
  //       dateFilter: 'invalid'
  //     });
  //   }
  //   return () => {};
  // }, [dateFilter]);

  return {
    companyName,
    changeCompanyName,
    companyNameValidCheck,
    iconImgUrl,
    changeIconImgUrl,
    iconImgUrlValidCheck,
    viewUrl,
    validInfo,
    dateFilter,
    setDateFilter,
    clickCreate,
    closeModal
  };
};
