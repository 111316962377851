import { ReactElement, useEffect, useState } from 'react';
import { TPInputCheckBox } from '../input/TPInput.checkBox';
import { SelectList, FilterInput, Gap, FilterWrap } from './TPSelect.Style';
import { IconBulUp, IconBulDown } from '@resources/icon';

/**
 * @description Select check
 * @param props
 */
export const TPSelectCheck = (props: {
  label: string | ReactElement;
  optionList: readonly {
    code: string;
    label: string;
    text?: string;
  }[];
  useOptionLabel?: boolean;
  useFilterStatus?: { [key: string]: string | [string, string] };
  selectOptions: string[];
  changeSelect: (state: any) => void;
}) => {
  const { label, useOptionLabel, optionList, selectOptions, changeSelect } =
    props;
  const [showCheckList, setShowCheckList] = useState(false);

  let showSelectValue = optionList
    .filter((el) => selectOptions.includes(el.code))
    .map((el) => el.label)
    .join(', ');

  if (showSelectValue.length > 10) {
    showSelectValue = `${showSelectValue}`;
  }

  useEffect(() => {
    const actualOptionCount = optionList.filter(
      (option) => option.code !== ''
    ).length;
    if (
      actualOptionCount === selectOptions.length &&
      optionList.some((el) => el.label === '전체') &&
      !selectOptions.includes('')
    ) {
      changeSelect('');
    }
  }, [optionList, selectOptions]);

  return (
    <FilterWrap
      onMouseEnter={() => {
        setShowCheckList(true);
      }}
      onMouseLeave={() => {
        setShowCheckList(false);
      }}
    >
      <FilterInput>
        <div className="label--checked">
          <span className="label">{label}</span>
          <span className="checked">{showSelectValue}</span>
        </div>
        {showCheckList ? (
          <IconBulDown className="icon" />
        ) : (
          <IconBulUp className="icon" />
        )}
      </FilterInput>
      <Gap />
      {showCheckList && (
        <SelectList showCheckList={showCheckList}>
          {optionList.map((item, index) => (
            <TPInputCheckBox
              option={useOptionLabel ? item.label : item.text ?? ''}
              optionKey={item.code}
              selectOptions={selectOptions}
              onClick={changeSelect}
              key={index}
            />
          ))}
        </SelectList>
      )}
    </FilterWrap>
  );
};
