import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useGetProjectTitleValid } from '@hooks/project/getProjectTitleValid';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { usePageFunc } from '@hooks/utils';

export interface IInitData {
  projectId: string;
  projectTitle: string;
  projectKey: string;
  companyName: string;
  contractType: string;
  startDateTime: string;
  endDateTime: string;
  machineTranslationType: string;
  prompt: string;
}

/**
 * /projectManage
 * @description 프로젝트 정보 수정
 * @param {IInitData} initData
 * @returns {projectTitle: string,
 *     setProjectTitle: function,
 *     validTitleFunc: function,
 *     projectKey: string,
 *     companyId: string,
 *     contractType: string,
 *     setContractType: function,
 *     dateFilter: { startDateTime: string; endDateTime: string },
 *     setDateFilter: function,
 *     validInfo: { projectTitle: string, projectKey: string, companyId: string, dateFilter: string }
 *     clickCreate: function,
 *     closeModal: function}
 */
export const usePutProject = (initData?: IInitData) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const { getDateTime } = usePageFunc();
  const { titleValidData, titleValid, validTitle, validTitleError } =
    useGetProjectTitleValid();

  const [machineTranslationType, setMachineTranslationType] = useState('NMT');
  const [prompt, setPrompt] = useState('');
  const [projectTitle, setProjectTitle] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [contractType, setContractType] = useState('CASE');
  const [dateFilter, setDateFilter] = useState<{
    startDateTime: string;
    endDateTime: string;
  }>({
    startDateTime: '',
    endDateTime: ''
  });
  const [validInfo, setValidInfo] = useState({
    projectTitle: '',
    projectKey: '',
    companyId: '',
    dateFilter: ''
  });

  const changeProjectTitle = (value: string) => {
    if (initData?.projectTitle === value) {
      setValidInfo({ ...validInfo, projectTitle: 'valid' });
    } else {
      setValidInfo({ ...validInfo, projectTitle: '' });
    }
    setProjectTitle(value);
  };

  const validTitleFunc = () => {
    titleValid(projectTitle);
  };

  const clickCreate = () => {
    alertHandle({
      title: '수정 확인',
      text: '입력한 내용으로 수정하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        createProject();
        navigate('/project', { replace: true });
      }
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '수정 취소',
      text: '수정 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/project');
      }
    });
  };

  //#region api call
  const getData = async () => {
    const { startDateTime, endDateTime } = dateFilter;
    return await api
      .put('/projectManage', {
        machineTranslationType,
        prompt,
        projectId: initData?.projectId,
        projectTitle,
        contractType,
        startDateTime: getDateTime({
          date: new Date(startDateTime),
          filter: 'start'
        }),
        endDateTime: getDateTime({ date: new Date(endDateTime), filter: 'end' })
      })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: createProject } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      navigate('/project', { replace: true });
      openHandle({ text: '프로젝트가 수정되었습니다.', severity: 'success' });
      queryClient.invalidateQueries(['/projectManage/allList']);
    },
    onError: () => {
      openHandle({
        text: `프로젝트 수정에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  useEffect(() => {
    if (validTitle) {
      const err = validTitleError as any;
      if (err.status === 400) {
        setValidInfo({
          ...validInfo,
          projectTitle: 'invalid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          projectTitle: 'invalid:regex'
        });
      }
    } else {
      if (titleValidData?.status === 200) {
        setValidInfo({
          ...validInfo,
          projectTitle: 'valid'
        });
      }
    }
    return () => {};
  }, [titleValidData, validTitle]);

  useEffect(() => {
    if (dateFilter.startDateTime && dateFilter.endDateTime) {
      setValidInfo({
        ...validInfo,
        dateFilter: 'valid'
      });
    } else {
      setValidInfo({
        ...validInfo,
        dateFilter: 'invalid'
      });
    }
    return () => {};
  }, [dateFilter]);

  useEffect(() => {
    if (initData) {
      setMachineTranslationType(initData.machineTranslationType);
      setPrompt(initData.prompt);
      setProjectTitle(initData.projectTitle);
      setProjectKey(initData.projectKey);
      setCompanyId(initData.companyName);
      setContractType(initData.contractType);
      setDateFilter({
        startDateTime: initData.startDateTime,
        endDateTime: initData.endDateTime
      });
      setValidInfo({
        ...validInfo,
        projectTitle: 'valid',
        projectKey: 'valid',
        companyId: 'valid'
      });
    }
    return () => {};
  }, [initData]);

  return {
    machineTranslationType,
    setMachineTranslationType,
    prompt,
    setPrompt,
    projectTitle,
    changeProjectTitle,
    validTitleFunc,
    projectKey,
    companyId,
    contractType,
    setContractType,
    dateFilter,
    setDateFilter,
    validInfo,
    clickCreate,
    closeModal
  };
};
