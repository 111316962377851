import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CommonStyle } from '@pages/modal/Common.style';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconCalendar, IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPInputText } from '@units/input/TPInput.text';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box } from '@mui/material';
import { usePutCompany } from '@hooks/company/putCompany';
import { format } from 'date-fns';
import _ from 'lodash';
import { Color } from '@common/styles/color';
import { TPButtonActionCalendar } from '@src/units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@src/hooks/modal/useRangeCalendar';

const CompanyModifyPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { companyId } = useParams();
  const {
    companyName,
    changeCompanyName,
    companyNameValidCheck,
    iconImgUrl,
    changeIconImgUrl,
    iconImgUrlValidCheck,
    viewUrl,
    validInfo,
    dateFilter,
    setDateFilter,
    clickCreate,
    closeModal
  } = usePutCompany(companyId as string);

  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'dateRange'
    // dateLimit: { fromLimit: new Date() }
  });

  return (
    <div css={[CommonStyle, alertWrap]}>
      <button className="alertClose" onClick={closeModal}>
        <IconModalClose />
      </button>
      <div>
        <TPHeadlineBasic decoration type="h2">
          고객사 정보 수정
        </TPHeadlineBasic>
      </div>
      <div ref={scrollRef} className="modal--div--box">
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'고객사명'}
            name={'companyName'}
            value={companyName}
            changeText={(e: any) => {
              changeCompanyName(e.target.value);
            }}
            required={true}
            placeholder={'고객사명을 입력해 주세요.'}
            helperText={
              _.includes(validInfo.companyName, 'invalid') ? (
                validInfo.companyName === 'invalid:regex' ? (
                  <span>중복된 고객사명입니다.</span>
                ) : (
                  <span>고객사명은 필수입니다.</span>
                )
              ) : validInfo.companyName === 'valid' ? (
                <span style={{ color: Color.black }}>
                  등록 가능한 고객사명입니다.
                </span>
              ) : (
                ''
              )
            }
          />
          <TPButtonBasic
            size={'large'}
            label={'중복 확인'}
            variant={'contained'}
            onClick={companyNameValidCheck}
            disabled={validInfo.companyName === 'valid'}
          />
        </div>
        <div>
          <div className={'input--text--wrap'}>
            <TPInputText
              label={'CI'}
              name={'ci'}
              value={iconImgUrl}
              changeText={(e: any) => {
                changeIconImgUrl(e.target.value);
              }}
              required={false}
              placeholder={'고객사 CI 주소를 입력해 주세요.'}
              helperText={
                _.includes(validInfo.iconImgUrl, 'invalid') ? (
                  validInfo.iconImgUrl === 'invalid:regex' ? (
                    <span>CI 주소가 올바르지 않습니다.</span>
                  ) : (
                    <span>CI 주소는 필수입니다.</span>
                  )
                ) : validInfo.iconImgUrl === 'valid' ? (
                  <span style={{ color: Color.black }}>
                    등록 가능한 CI 주소입니다.
                  </span>
                ) : (
                  ''
                )
              }
            />
            <TPButtonBasic
              size={'large'}
              label={'CI 확인'}
              variant={'contained'}
              onClick={iconImgUrlValidCheck}
              disabled={iconImgUrl === ''}
            />
          </div>
          {viewUrl ? (
            <div className={'input--text--ci'}>
              <img src={viewUrl} alt={''} />
            </div>
          ) : null}
        </div>
        <div>
          <div className="title">
            계약기간 <span className="color--red">*</span>
          </div>
          <TPButtonActionCalendar
            onClick={() => {
              openHandle('companyRegister', dateFilter, setDateFilter);
            }}
            outline={true}
            size={'medium'}
            content={
              dateFilter.startDateTime && dateFilter.endDateTime
                ? `${format(
                    new Date(dateFilter.startDateTime),
                    'yyyy.MM.dd'
                  )} - ${format(
                    new Date(dateFilter.endDateTime),
                    'yyyy.MM.dd'
                  )}`
                : '시작일과 종료일을 입력해주세요.'
            }
            icon={<IconCalendar />}
          />
          <Calendar />
          {validInfo.dateFilter === 'invalid' ? (
            <p className="valid">* 계약기간을 입력해주세요.</p>
          ) : null}
        </div>
      </div>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={8}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="수정하기"
            fullWidth
            disabled={
              validInfo.companyName === 'invalid' ||
              validInfo.companyName === 'invalid:regex' ||
              validInfo.companyName === '' ||
              (iconImgUrl !== '' && validInfo.iconImgUrl !== 'valid')
            }
            onClick={clickCreate}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CompanyModifyPage;
