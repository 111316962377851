import React from 'react';
import { IconDetail } from '@src/resources/icon';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/component/ColumnHead.Comp';
import { useAlert as alert } from '@hooks/modal/useAlert';
import { TPTooltipBasic } from '@units/tooltip/TPTooltip.Basic';
import { useNavigate as Navigate } from 'react-router-dom';
import { useDeleteCompany as DeleteCompany } from '@hooks/company/deleteCompany';

export const companyColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'companyId',
      value: '순번',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'companyName',
      value: '고객사 명',
      width: 500,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'iconImgUrl',
      value: 'CI',
      width: 350,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return rowData.iconImgUrl.length ? (
          <TPTooltipBasic title={rowData.companyName}>
            <img
              src={rowData.iconImgUrl}
              alt={rowData.companyName}
              height={40}
              style={{ maxWidth: '200px' }}
            />
          </TPTooltipBasic>
        ) : null;
      }
    },
    {
      code: 'startDateTime',
      value: '계약 시작일',
      width: 200,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return format(new Date(rowData.startDateTime), 'yyyy-MM-dd');
      }
    },
    {
      code: 'endDateTime',
      value: '계약 종료일',
      width: 200,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return format(new Date(rowData.endDateTime), 'yyyy-MM-dd');
      }
    },

    {
      code: 'updatedDateTime',
      value: '마지막 업데이트',
      width: 260,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        let lastUpdate = `${format(
          new Date(rowData.updatedDateTime),
          'yyyy-MM-dd'
        )}`;
        if (rowData.lastUpdateUserName) {
          lastUpdate += `<br/><strong>${rowData.lastUpdateUserName}</strong>`;
        }
        return <p dangerouslySetInnerHTML={{ __html: lastUpdate }}></p>;
      }
    },
    {
      code: 'option',
      value: '',
      width: 24,
      renderRowData: (data: any, rowData: any) => {
        const { openHandle: openAlert } = alert();
        const navigate = Navigate();
        const { deleteCompany } = DeleteCompany();

        let menusArr: {
          clickMenu: () => void;
          label: string;
          to?: string;
          state?: {};
        }[] = [
          {
            clickMenu: () => {
              navigate(`/company/modify/${rowData.companyId}`);
            },
            label: '수정하기'
          },
          {
            clickMenu: () => {
              openAlert({
                title: '고객사 삭제',
                text: `${rowData.companyName} 을(를) 삭제하시겠습니까?`,
                cancelLabel: '취소',
                ok: () => {
                  deleteCompany(rowData);
                }
              });
            },
            label: '삭제하기'
          }
        ];

        return menusArr.length > 0 ? (
          <TPPopoverMenu
            popoverMenus={menusArr}
            clickPopoverButton={(e: any) => {
              e.stopPropagation();
            }}
          >
            <TPButtonAction icon={<IconDetail />} size="small" />
          </TPPopoverMenu>
        ) : null;
      }
    }
  ] as const;
