import React, { useRef } from 'react';
import { IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { CommonStyle } from '@pages/modal/Common.style';
import { alertWrap } from '@hooks/modal/Modal.style';
import { TPInputText } from '@units/input/TPInput.text';
import { TPInputCheckBox } from '@units/input/TPInput.checkBox';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { Box } from '@mui/material';
import { usePostUsers } from '@hooks/users/postUsers';
import _ from 'lodash';
import { Color } from '@common/styles/color';

const AccountRegisterPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const {
    email,
    setEmail,
    validEmail,
    name,
    setName,
    slackId,
    changeSlackId,
    validSlackId,
    roleList,
    changeRoleList,
    avatarImgUrl,
    validInfo,
    clickCreate,
    clickClose
  } = usePostUsers();

  return (
    <div css={[CommonStyle, alertWrap]}>
      <button className="alertClose" onClick={clickClose}>
        <IconModalClose />
      </button>
      <div>
        <TPHeadlineBasic decoration type="h2">
          신규 계정 등록
        </TPHeadlineBasic>
      </div>
      <div ref={scrollRef} className="modal--div--box">
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'이메일'}
            name={'email'}
            value={email}
            changeText={(e: any) => {
              setEmail(e.target.value);
            }}
            required={true}
            placeholder={'이메일을 입력해 주세요.'}
            helperText={
              _.includes(validInfo.email, 'invalid') ? (
                validInfo.email === 'invalid:regex' ? (
                  <span>이메일은 필수입니다.</span>
                ) : (
                  <span>이미 등록된 이메일입니다.</span>
                )
              ) : validInfo.email === 'valid' ? (
                <span style={{ color: Color.black }}>
                  등록 가능한 이메일입니다.
                </span>
              ) : (
                ''
              )
            }
          />
          <TPButtonBasic
            size={'large'}
            label={'중복 확인'}
            variant={'contained'}
            onClick={validEmail}
          />
        </div>

        <TPInputText
          label={'사용자명'}
          name={'name'}
          value={name}
          changeText={(e: any) => {
            setName(e.target.value);
          }}
          required={true}
          placeholder={'사용자 이름을 입력해 주세요.'}
          helperText={
            validInfo.name === 'invalid' ? '사용자 이름은 필수입니다.' : ''
          }
        />
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'슬랙 로우 ID'}
            name={'slackId'}
            value={slackId}
            changeText={(e: any) => {
              changeSlackId(e.target.value);
            }}
            required={true}
            placeholder={'슬랙 로우 ID를 입력해 주세요.'}
            helperText={
              _.includes(validInfo.slackId, 'invalid') ? (
                validInfo.slackId === 'invalid:regex' ? (
                  <span>슬랙 로우 ID를 확인해 주세요.</span>
                ) : (
                  <span>슬랙 로우 ID는 필수입니다.</span>
                )
              ) : validInfo.slackId === 'valid' ? (
                <span style={{ color: Color.black }}>
                  등록 가능한 슬랙 로우 ID입니다.
                </span>
              ) : (
                ''
              )
            }
          />
          <TPButtonBasic
            size={'large'}
            label={'ID 확인'}
            variant={'contained'}
            onClick={validSlackId}
          />
        </div>

        <div>
          <div className="title">
            권한 <span className="color--red">*</span>
          </div>
          <div className={'input--checkbox--wrap'}>
            <TPInputCheckBox
              option={'PM리더'}
              optionKey={'PML'}
              selectOptions={roleList}
              onClick={changeRoleList}
            />
            <TPInputCheckBox
              option={'PM'}
              optionKey={'PM'}
              selectOptions={roleList}
              onClick={changeRoleList}
            />
            <TPInputCheckBox
              option={'언어리더'}
              optionKey={'LL'}
              selectOptions={roleList}
              onClick={changeRoleList}
            />
            <TPInputCheckBox
              option={'작업자'}
              optionKey={'TL'}
              selectOptions={roleList}
              onClick={changeRoleList}
            />
          </div>
          {validInfo.roleList === 'invalid' ? (
            <p className="valid">권한을 1개 이상 선택해주세요.</p>
          ) : null}
        </div>

        {avatarImgUrl ? (
          <div>
            <div className="title">프로필 사진</div>
            <TPAvatarBasic src={avatarImgUrl} size={'xlarge'} />
          </div>
        ) : null}
      </div>

      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={8}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={clickClose}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="등록하기"
            fullWidth
            disabled={
              _.includes(validInfo, 'invalid') ||
              _.includes(validInfo, 'invalid:regex') ||
              _.includes(validInfo, '')
            }
            onClick={clickCreate}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default AccountRegisterPage;
