import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { CommonStyle } from '@pages/modal/Common.style';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconCalendar, IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPInputText } from '@units/input/TPInput.text';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { TPButtonActionCalendar } from '@units/button/TPButton.ActionCalendar';
import { format } from 'date-fns';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useGetProject, IInitData } from '@hooks/project/getProject';
import { usePutProject } from '@hooks/project/putProject';
import { Color } from '@common/styles/color';
import { TPSelectBasic } from '@src/units/select/TPSelect.basic';
import { machineTranslationTypeList } from '@src/hooks/project/postProject';
import { TPInputCheckBox } from '@src/units/input/TPInput.checkBox';

const ProjectModifyPage = () => {
  const params = useParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'dateRange'
    // dateLimit: { fromLimit: new Date() }
  });
  const projectId = params.projectId as string;
  const { projectData } = useGetProject(projectId);
  const {
    machineTranslationType,
    setMachineTranslationType,
    prompt,
    setPrompt,
    projectTitle,
    changeProjectTitle,
    validTitleFunc,
    projectKey,
    companyId,
    contractType,
    setContractType,
    dateFilter,
    setDateFilter,
    validInfo,
    clickCreate,
    closeModal
  } = usePutProject(projectData as IInitData);

  return (
    <div css={[CommonStyle, alertWrap]}>
      <button className="alertClose" onClick={closeModal}>
        <IconModalClose />
      </button>
      <div>
        <TPHeadlineBasic decoration type="h2">
          프로젝트 정보 수정
        </TPHeadlineBasic>
      </div>
      <div ref={scrollRef} className="modal--div--box">
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'프로젝트명'}
            name={'title'}
            value={projectTitle}
            changeText={(e: any) => {
              changeProjectTitle(e.target.value);
            }}
            required={true}
            placeholder={'프로젝트명을 입력해 주세요.'}
            helperText={
              _.includes(validInfo.projectTitle, 'invalid') ? (
                validInfo.projectTitle === 'invalid:regex' ? (
                  <span>중복된 프로젝트명입니다.</span>
                ) : (
                  <span>프로젝트명은 필수입니다.</span>
                )
              ) : validInfo.projectTitle === 'valid' ? (
                <span style={{ color: Color.black }}>
                  등록 가능한 프로젝트명입니다.
                </span>
              ) : (
                ''
              )
            }
          />
          <TPButtonBasic
            size={'large'}
            label={'중복 확인'}
            variant={'contained'}
            onClick={validTitleFunc}
            disabled={validInfo.projectTitle === 'valid'}
          />
        </div>
        <div>
          <div className="title">프로젝트 키</div>
          <Box component={'p'} textAlign={'left'}>
            {projectKey}
          </Box>
        </div>
        <div>
          <div className="title">고객사</div>
          <Box component={'p'} textAlign={'left'}>
            {companyId}
          </Box>
        </div>
        {/* <div>
          <div className="title">견적 유형</div>
          <Box component={'p'} textAlign={'left'}>
            {contractType === 'CASE' ? '건별 발송' : '월별 발송'}
          </Box>
        </div> */}
        <div>
          <div className="title">
            견적 유형 <span className="color--red">*</span>
          </div>
          <div className={'input--checkbox--wrap'}>
            <TPInputCheckBox
              option={'건별 발송'}
              optionKey={'CASE'}
              selectOptions={[contractType]}
              onClick={setContractType}
            />
            <TPInputCheckBox
              option={'월별 발송'}
              optionKey={'MONTHLY'}
              selectOptions={[contractType]}
              onClick={setContractType}
            />
          </div>
        </div>
        <div>
          <div className="title">
            계약기간 <span className="color--red">*</span>
          </div>
          <TPButtonActionCalendar
            onClick={() => {
              openHandle('projectRegister', dateFilter, setDateFilter);
            }}
            outline={true}
            size={'medium'}
            content={
              dateFilter.startDateTime && dateFilter.endDateTime
                ? `${format(
                    new Date(dateFilter.startDateTime),
                    'yyyy.MM.dd'
                  )} - ${format(
                    new Date(dateFilter.endDateTime),
                    'yyyy.MM.dd'
                  )}`
                : '시작일과 종료일을 입력해주세요.'
            }
            icon={<IconCalendar />}
          />
          <Calendar />
          {validInfo.dateFilter === 'invalid' ? (
            <p className="valid">* 계약기간을 입력해주세요.</p>
          ) : null}
        </div>
        <div>
          <div className="title">기계번역 유형</div>

          <TPSelectBasic
            large={true}
            value={machineTranslationType}
            options={[...machineTranslationTypeList]}
            changeSelect={(e) => {
              setMachineTranslationType(e.target.value);
            }}
            autoWidth={false}
            isDisabled={false}
          />
        </div>
        <div>
          <TPInputText
            label={'프롬프트'}
            name={'prompt'}
            value={prompt}
            changeText={(e: any) => {
              setPrompt(e.target.value);
            }}
            placeholder={'프롬프트를 입력해 주세요.'}
          />
        </div>
      </div>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={8}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="수정하기"
            fullWidth
            disabled={
              _.includes(validInfo, 'invalid') ||
              _.includes(validInfo, 'invalid:regex') ||
              _.includes(validInfo, '')
            }
            onClick={clickCreate}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ProjectModifyPage;
